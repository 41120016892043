<template>
  <div class="group p-4 flex-col transition-all duration-300 ease-in-out flex">
    <router-link :to="to" class="text-white uppercase">
      <slot />
    </router-link>
    <div
      v-if="!active"
      class="h-1 w-full bg-cognac opacity-0 group-hover:opacity-100"
    ></div>
    <div v-if="active" class="h-1 w-full bg-cognac"></div>
  </div>
</template>

<script>
export default {
  name: "Navitem",
  props: ["to", "active"]
};
</script>
