import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//component imports
import VueCarousel from "vue-carousel";
import vmodal from "vue-js-modal";
import VueSweetalert2 from 'vue-sweetalert2';

//style imports
import "./assets/css/tailwind.css";
import "./assets/css/app.css";
import "./assets/css/hamburger.css";
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueCarousel);
Vue.use(vmodal);
Vue.use(VueSweetalert2);

const moment = require('moment')
require('moment/locale/nl')
 
Vue.use(require('vue-moment'), {
    moment
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
