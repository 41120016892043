import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    images: [],
    menus: [],
    headers: [],
    events: [],
    takeaway: [],
    errors: []
  },
  mutations: {
    GET_IMAGES(state) {
      axios
        .get("https://apps.egidius.io/items/meyerwinesandbites_nl_images")
        .then(data => {
          let files = [];
          data.data.data.forEach(element => {
            files.push(element.image);
          });

          let urls = [];
          files.forEach(file => {
            urls.push("https://apps.egidius.io/assets/" + file + "?q=60")
          });
          state.images = urls;
        });
    },

    async GET_MENUS(state) {
      axios
        .get("https://apps.egidius.io/items/meyerwinesandbites_nl_menus?sort=sort")
        .then(data => {
          let files = [];
          data.data.data.forEach(element => {
            files.push(element.image);
          });
          let urls = [];
          files.forEach(file => {
            urls.push("https://apps.egidius.io/assets/" + file + "?q=60")
          });
          state.menus = urls;
        });
    },

    async GET_HEADERS(state) {
      axios
        .get("https://apps.egidius.io/items/meyerwinesandbites_nl_header")
        .then(data => {
          const d = data.data.data
          let files = [];
          d.forEach(element => {
            files.push(element.image);
          });

          let urls = [];
          files.forEach(file => {
            urls.push("https://apps.egidius.io/assets/" + file + "?q=60")
          });
          state.headers = urls;
        });
    },

    async GET_EVENTS(state) {
      // axios
      //   .get("https://directus.meyerwinesandbites.nl/items/events?sort=date,start_time,end_time&filter[date][_gte]=$NOW")
      //   .then(data => {
      //     let events = [];
      //     data.data.data.forEach(event => {
      //       if (event.image) {
      //         event.image = "https://directus.meyerwinesandbites.nl/assets/" + event.image + "?q=60"
      //         events.push(event);
      //       }else {
      //         events.push(event);
      //       }
      //     });
      //     state.events = events;
      //   });
      state.events = [];
    },

    async GET_TAKEAWAY(state) {
      axios
        .get("https://cms.mengelberg.eu/meyer-wnb/items/takeaway?sort=from_date,to_date,price&filter[to_date][gte]=now&limit=8")
        .then(data => {
          let takeaway = [];
          data["data"]["data"].forEach(course => {
            if (course.image) {
              axios.get("https://cms.mengelberg.eu/meyer-wnb/files/" + course.image)
                .then(data => {
                  course.image = data.data.data.data.full_url
                  takeaway.push(course);
                });
            }else {
              takeaway.push(course);
            }
          });
          state.takeaway = takeaway;
        });
    }
  },
  actions: {
    getImages(context) {
      context.commit("GET_IMAGES");
    },
    getMenus(context) {
      context.commit("GET_MENUS");
    },
    getHeaders(context) {
      context.commit("GET_HEADERS");
    },
    getEvents(context) {
      context.commit("GET_EVENTS");
    },
    getTakeaway(context) {
      context.commit("GET_TAKEAWAY");
    }
  },
  modules: {}
});
