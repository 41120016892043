<template>
  <div class="w-full bg-gray-200 flex shadow-sm">
    <div class="flex flex-col justify-center items-center bg-primary min-w-24 min-h-24 md:min-w-32 md:min-h-32 flex-none">
      <span class="text-cognac-light text-5xl font-display leading-none">{{ date | moment('DD') }}</span>
      <span class="text-white font-2xl">{{ date | moment('MMM') }}</span>
    </div>
    <div class="w-24 md:w-32" v-if="image">
      <img :src="image" :alt="title" class="min-h-full md:h-32 md:w-32 object-cover">
    </div>
    <div class="flex flex-col flex-wrap p-3">
      <div class="text-xl font-bold">{{ title }}</div>
      <div class="text-xs">{{ description }}</div>
      <div class="mt-auto" v-if="start_time && end_time">{{ [ start_time, "HH:mm:ss" ] | moment("HH.mm") }}-{{ [ end_time, "HH:mm:ss" ] | moment("HH.mm") }}</div>
      <div class="mt-auto" v-if="start_time && !end_time">{{ [ start_time, "HH:mm:ss" ] | moment("HH.mm") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventItem",
  props: ['date', 'image', 'title', 'description', 'start_time', 'end_time']
}
</script>
