<template>
  <div class="flex flex-col justify-items-center items-center">
    <div class="flex flex-row justify-between items-center w-full p-3">
      <div class="text-2xl font-bold">Reserveren</div>
      <slot name="top-close-button"></slot>
    </div>
    <div class="w-full p-4 grid grid-cols-2 gap-2">
      <div class="w-full px-3 col-span-2" v-if="errors.lenght">
        <span>Oeps</span>
        <div v-for="error in errors" v-bind:key="error">
          <span class="text-sm text-red-500">{{ error }}</span>
        </div>
      </div>
      <!-- naam -->
      <div class="w-full px-3 col-span-2">
        <label class="block text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
          Naam
        </label>
        <input v-model="name" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="John Doe">
      </div>
      <!-- email -->
      <div class="w-full px-3 col-span-2 md:col-span-1">
        <label class="block text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
          Email
        </label>
        <input v-model="email" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="email" placeholder="john@doe.com">
      </div>
      <!-- telefoonnummer -->
      <div class="w-full px-3 col-span-2 md:col-span-1">
        <label class="block text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
          Telefoonnummer
        </label>
        <input v-model="phone" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="number" placeholder="0619920509">
      </div>
      <!-- datum -->
      <div class="w-full px-3 col-span-2 md:col-span-1">
        <label class="block text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
          Datum
        </label>
        <input v-model="date" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="date">
      </div>
      <!-- tijdslot -->
      <div class="w-full px-3 col-span-2 md:col-span-1">
        <label class="block text-gray-700 text-xs font-bold mb-2" for="grid-state">
          Tijdslot
        </label>
        <div class="relative">
          <select v-model="timeslot" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
            <option>17.00-19.00</option>
            <option>19.00-21.00</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
        </div>
      </div>
      <!-- aantal personen -->
      <div class="w-full px-3 col-span-2 md:col-span-1">
        <label class="block text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
          Aantal personen
        </label>
        <input v-model="guests" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="number" placeholder="2">
      </div>
      <!-- opmerkingen -->
      <div class="w-full px-3 col-span-2">
        <label class="block text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
          Vragen/Opmerkingen
        </label>
        <textarea v-model="remarks" rows="4" class="resize-none appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name"></textarea>
      </div>
      <!-- reserveren -->
      <div class="px-3 py-4 col-span-2">
        <button @click="submitReservation()" class="bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" type="button">
        Reserveren
      </button>
      <slot name="close-button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ReservationForm",
  data: function () {
    return {
      form_data: [],
      name: '',
      email: '',
      phone: '',
      date: '',
      timeslot: '',
      guests: '',
      remarks: '',
      errors: [],
    }
  },
  methods: {
    submitReservation () {

      this.errors = [];

      let formData = {
        'name': this.name,
        'email': this.email,
        'phone': this.phone,
        'date': this.date,
        'timeslot': this.timeslot,
        'guests': this.guests,
        'remarks': this.remarks
      }

      //check if name is filled in and is valid
      if (!this.name) {
          this.errors.push('Vul je naam in');
          window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
          });
          return;
      }

      //check if email is filled in and is valid
      if (!this.email) {
          this.errors.push('Vul je email in');
          window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
          });
          return;
      } else if (!this.validEmail(this.email)) {
          this.errors.push('Vul een geldige email in');
          window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
          });
          return;
      }

      //check if phone is filled in and is valid
      if (!this.phone) {
          this.errors.push('Vul je telefoonnummer in');
          window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
          });
          return;
      } else if (!this.validTelephone(this.phone)) {
          this.errors.push('Vul een geldig 06-nummer in.');
          window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
          });
          return;
      }

      //check if date is filled in and is valid
      if (!this.date) {
          this.errors.push('Vul een datum voor uw reservering in.');
          window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
          });
          return;
      }

      //check if timeslot is filled in and is valid
      if (!this.timeslot) {
          this.errors.push('Vul een tijdslot in');
          window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
          });
          return;
      }

      //check if guests is filled in and is valid
      if (!this.guests) {
          this.errors.push('Vul het aantal personen in');
          window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
          });
          return;
      }

      //check if errors
      if (this.errors.length < 1) {
        //no errors
         axios
          .post('https://cms.mengelberg.eu/meyer-wnb/items/reservations', formData)
            .then(() => {
              this.$modal.hide("form-modal");
              this.$swal.fire({
                title: 'Yes!',
                text: 'Het reserveren is gelukt',
                icon: 'success',
                confirmButtonText: 'Ok'
              })
            }) 
            .catch((error) => {
              console.log(error)
              this.$modal.hide("form-modal");
              this.$swal.fire({
                title: 'Oeps',
                text: 'Er ging iets fout tijdens het reserveren',
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            })
      }
    },
    validEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    validTelephone: function (telephone) {
        var re = /^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i;
        return re.test(telephone);
    }
  },
  mounted: function () {
    let d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    this.date = [year, month, day].join('-');
  }
}
</script>
